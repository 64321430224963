import React, { useContext } from "react";

import { Footer } from "../../../components/Footer/Footer";
import { FormationContactContext } from "../../../helpers/pagesContext";
import { ContactForm } from "./Components/ContactForm/ContactForm";
import { Hero } from "./Components/Hero/Hero";
import * as styles from "./FormationContact.module.css";

export default function FormationContact() {
  const { node_locale, footerBackground } = useContext(FormationContactContext);

  return (
    <>
      <Hero />
      <ContactForm />
      <div
        className={styles.footer}
        style={{
          backgroundImage: `url(${footerBackground.file.url})`,
        }}
      >
        <div className={styles.overFooter} />
        <Footer lang={node_locale} />
      </div>
    </>
  );
}

import { graphql } from "gatsby";
import React from "react";

import Layout from "../../components/Layout";
import Seo from "../../components/seo";
import { FormationContactContext } from "../../helpers/pagesContext";
import { FORMATION } from "../../helpers/route";
import FormationContactScene from "../../scenes/Formation/FormationContact/FormationContact";

export default function FormationContact(props) {
  return (
    <Layout
      navbarTheme="dark"
      currentRoute={FORMATION}
      data={props.data.contentfulMenuLabel}
    >
      <Seo
        title="Formation contact"
        description="Zenity propose des formations spécialisées dans le métier du test logiciel : certifiée Qaliopi, propose des formations telles que les API, Cucumber, Gherkin, Jira, Xray, Jenkins, Agiles, Selenium, bdd, DevOps, tests exploratoires, tests logiciels..."
      />
      <FormationContactContext.Provider
        value={{
          ...props.data.contentfulZac,
          ...props.data.contentfulFormationContactForm,
        }}
      >
        <FormationContactScene />
      </FormationContactContext.Provider>
    </Layout>
  );
}

export const pageQuery = graphql`
  query MyQuery($locale: String!) {
    contentfulZac(node_locale: { eq: $locale }) {
      zacFormReturn
      zacFormBackground1 {
        gatsbyImageData
        file {
          fileName
          url
        }
      }
      zacFormBackground2 {
        gatsbyImageData
        file {
          fileName
          url
        }
      }
    }
    contentfulFormationContactForm(node_locale: { eq: $locale }) {
      node_locale
      emailError
      phoneFormatError
      emailRequired
      phoneRequired
      messageError
      messageValidation
      title
      mail
      message
      messagePlaceholder
      name
      phone
      question
      questionList
      node_locale
      firstname
      cta
      footerBackground {
        gatsbyImageData
        file {
          fileName
          url
        }
      }
      heroTitle {
        raw
      }
      catchphraseContact {
        raw
      }
    }
    contentfulMenuLabel(node_locale: { eq: $locale }) {
      adventure
      career
      cv
      identity
      innovation
      insideZenity
      inspirations
      node_locale
      recrute
      univers
      contact
      accept
      decline
      cookie
      knowMore
      formation
      ourFormation
      zac
      services
      zenityFactory
    }
  }
`;

import CloseIcon from "@mui/icons-material/Close";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { useSnackbar } from "notistack";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";
import Select from "react-select";

import { FormationContactContext } from "../../../../../helpers/pagesContext";
import { FormInputText } from "./components/FormInputText/FormInputText";
import * as styles from "./ContactForm.module.css";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    "*": {
      boxShadow: "none !important",
    },
    backgroundColor: "transparent",
    boxShadow: "none",
    border: state.isFocused
      ? "2px solid #28BCC6"
      : `1px solid ${provided.borderColor}`,
    "&:hover": {
      border: "2px solid #28BCC6",
    },
    transition: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    transition: "none",
    fontFamily: "Futura",
    fontWeight: "300",
    fontStyle: "italic",
    padding: "4px 40px",
    margin: "15px 0px",
    color: state.isSelected ? "#fff" : state.isFocused ? "#fff" : "#000",
    backgroundColor: state.isSelected
      ? "#28BCC6"
      : state.isFocused
      ? "#28BCC6"
      : provided.backgroundColor,
    "&:hover": {
      color: "#fff",
      backgroundColor: state.isSelected ? "#28BCC6" : "#28BCC6",
    },
  }),
};

const sx = {
  flex: 1,
  marginRight: "30px",
};

export const ContactForm = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      message: "",
      project: "",
    },
  });
  const {
    mail,
    messagePlaceholder,
    name,
    phone,
    question,
    questionList,
    firstname,
    cta,
    emailError,
    phoneFormatError,
    messageError,
    messageValidation,
    emailRequired,
    phoneRequired,
    catchphraseContact,
    zacFormBackground2,
  } = useContext(FormationContactContext);

  const onSubmit = async (data, e) => {
    e.preventDefault();

    try {
      const result = await fetch("/api/formation", {
        method: "POST",
        body: JSON.stringify(data),
        headers: { "Content-Type": "application/json" },
      });
      await result.json();
      reset();
      enqueueSnackbar(messageValidation, {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        action: (key) => (
          <>
            <CloseIcon
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                closeSnackbar(key);
              }}
            />
          </>
        ),
      });
    } catch (error) {
      enqueueSnackbar(error, {
        variant: "error",
      });
      console.log(error);
    }
  };

  return (
    <section
      className={styles.contact}
      style={{
        backgroundImage: `url(${zacFormBackground2.file.url})`,
      }}
    >
      <div className={styles.contactUs}>
        <div className={styles.contactUsForm}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <div className={styles.selectText}>{question} :</div>
              <div className={styles.select}>
                <Controller
                  name="project"
                  variant="standard"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      styles={customStyles}
                      options={questionList.map((val) => {
                        return { value: val, label: val };
                      })}
                    />
                  )}
                />
              </div>
            </div>
            <div>
              <FormInputText
                label={firstname}
                name="firstName"
                control={control}
                error={errors.firstName}
                sx={sx}
              />
              <FormInputText
                label={name}
                name="lastName"
                control={control}
                error={errors.lastName}
                sx={sx}
              />
            </div>
            <div>
              <FormInputText
                label={phone}
                name="phone"
                control={control}
                error={errors.phone}
                rules={{
                  required: phoneRequired,
                  pattern: {
                    value: /^[1-9][0-9]{7,14}$/i,
                    message: phoneFormatError,
                  },
                }}
                sx={sx}
              />
              <FormInputText
                label={mail}
                name="email"
                control={control}
                error={errors.email}
                rules={{
                  required: emailError,
                  pattern: {
                    value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/i,
                    message: emailRequired,
                  },
                }}
                sx={sx}
              />
            </div>
            <div className={styles.textarea}>
              <FormInputText
                label={messagePlaceholder}
                name="message"
                control={control}
                error={errors.message}
                rules={{ required: messageError }}
                multiline
                rows={6}
                variant="outlined"
                sx={sx}
              />
            </div>
            <input
              className={styles.contactUsFormSubmit}
              type="submit"
              value={cta}
            />
          </form>
          <div className={styles.catchphrase}>
            {renderRichText(catchphraseContact)}
          </div>
        </div>
      </div>
    </section>
  );
};

// extracted by mini-css-extract-plugin
export var catchphrase = "ContactForm-module--catchphrase--a07b9";
export var contact = "ContactForm-module--contact--c34f8";
export var contactUs = "ContactForm-module--contactUs--18ad9";
export var contactUsForm = "ContactForm-module--contactUsForm--f318c";
export var contactUsFormSubmit = "ContactForm-module--contactUsFormSubmit--60bbd";
export var contactUsMsg = "ContactForm-module--contactUsMsg--41d61";
export var contactUsMsgContact = "ContactForm-module--contactUsMsgContact--f806e";
export var contactUsMsgHead = "ContactForm-module--contactUsMsgHead--aa022";
export var select = "ContactForm-module--select--39ef4";
export var selectText = "ContactForm-module--selectText--60917";
export var textarea = "ContactForm-module--textarea--16da2";
import { Link } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import React, { useContext } from "react";

import { SlideTransition } from "../../../../../components/SlideTransition/SlideTransition";
import { localeLink } from "../../../../../helpers/localeLink";
import { FormationContactContext } from "../../../../../helpers/pagesContext";
import { FORMATION } from "../../../../../helpers/route";
import arrow from "../../../../../resources/images/opportunity-misc/arrow.svg";
import * as styles from "./Hero.module.css";

export const Hero = () => {
  const { heroTitle, zacFormBackground1, zacFormReturn } = useContext(
    FormationContactContext
  );

  return (
    <section
      className={styles.root}
      style={{
        backgroundImage: `url(${zacFormBackground1.file.url})`,
      }}
    >
      <SlideTransition thresholds={200} delay={0.2}>
        <div>{renderRichText(heroTitle)}</div>
      </SlideTransition>
      <div className={styles.breadcrumb}>
        <Link
          to={localeLink(
            typeof window !== "undefined"
              ? window.history.state
                ? window.history.state.prevPath
                : FORMATION
              : FORMATION
          )}
        >
          <img src={arrow} alt="ping" />
          <span>{zacFormReturn}</span>
        </Link>
      </div>
    </section>
  );
};

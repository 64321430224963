import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { Controller } from "react-hook-form";

const CustomTextField = styled(TextField, {
  shouldForwardProp: (props) => props !== "focusColor",
})((p) => ({
  "& label": {
    fontFamily: "Futura",
  },
  "& label.Mui-focused": {
    color: p.focusColor,
  },
  "& .MuiInput-underline:hover:before": {
    borderBottomColor: "#2f3f45!important",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#28BCC6",
  },
  "& .MuiFilledInput-underline:after": {
    borderBottomColor: "#cea463",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#28BCC6",
    },
  },
}));

export const FormInputText = ({
  name,
  control,
  error,
  rules,
  label,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <CustomTextField
          error={!!error}
          helperText={error ? error.message : ""}
          label={label}
          variant="standard"
          focusColor="#28BCC6"
          {...field}
          {...props}
        />
      )}
    />
  );
};
